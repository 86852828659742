import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import withRoot from './modules/withRoot';

import { useTranslation } from 'react-i18next';
import TermsIt from './i18n/locales/it/TermsIt';

function Terms() {
  const { i18n } = useTranslation();

  function termByLanguage() {
    /*
    if (i18n.language == 'en') {
      return <TermsEn />;
    } else if (i18n.language == 'es') {
      return <TermsEs />;
    } else if (i18n.language == 'it') {
      return <TermsIt />;
    }
      */

    return <TermsIt />;
  }
  return (
    <React.Fragment>
      <AppAppBar hideNavigation={true} />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>{termByLanguage()}</Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Terms);

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { Grid, Paper } from '@mui/material';

import presenation01 from '../../assets/presentation-image-01.png';
import presentation02 from '../../assets/presentation02.png';
import presentation03 from '../../assets/presentation03.png';
import presentation04 from '../../assets/presentation04.png';
import { useTranslation } from 'react-i18next';

import Feature01English from '../../assets/feature_01_english.png';
import Feature01Spanish from '../../assets/feature_01_spanish.png';
import Feature01Italian from '../../assets/feature_01_italian.png';

import Feature02English from '../../assets/feature_02_english.png';
import Feature02Spanish from '../../assets/feature_02_spanish.png';
import Feature02Italian from '../../assets/feature_02_italian.png';

import Feaure03English from '../../assets/feature_03_english.png';
import Feaure03Spanish from '../../assets/feature_03_spanish.png';
import Feaure03Italian from '../../assets/feature_03_italian.png';

import Feaure04English from '../../assets/feature_04_english.png';
import Feaure04Spanish from '../../assets/feature_04_spanish.png';
import Feaure04Italian from '../../assets/feature_04_italian.png';

import SilvioPresentaton1 from '../../assets/silvio_presentation_1.png';
import SilvioPresentation2 from '../../assets/silvio_presentation_2.png';
import SilvioPresentation3 from '../../assets/silvio_presentation_3.png';
import SilvioPresentation4 from '../../assets/silvio_presentation_4.png';

export default function ProductCategories() {
  const { t, i18n } = useTranslation();

  function localizedFeature01() {
    if (i18n.language == 'en') {
      return Feature01English;
    } else if (i18n.language == 'es') {
      return Feature01Spanish;
    } else if (i18n.language == 'it') {
      return Feature01Italian;
    }

    return Feature01English;
  }

  function localizedFeature02() {
    if (i18n.language == 'en') {
      return Feature02English;
    } else if (i18n.language == 'es') {
      return Feature02Spanish;
    } else if (i18n.language == 'it') {
      return Feature02Italian;
    }

    return Feature02English;
  }

  function localizedFeature03() {
    if (i18n.language == 'en') {
      return Feaure03English;
    } else if (i18n.language == 'es') {
      return Feaure03Spanish;
    } else if (i18n.language == 'it') {
      return Feaure03Italian;
    }

    return Feaure03English;
  }

  function localizedFeature04() {
    if (i18n.language == 'en') {
      return Feaure04English;
    } else if (i18n.language == 'es') {
      return Feaure04Spanish;
    } else if (i18n.language == 'it') {
      return Feaure04Italian;
    }

    return Feaure04English;
  }

  return (
    <Container
      id="features"
      component="section"
      maxWidth={false}
      sx={{ mt: 8, mb: 4, bgcolor: 'white', pt: 10, pb: 10 }}
    >
      <Typography variant="h4" align="center" component="h2">
        {'Caratteristiche'}
      </Typography>

      <Container
        sx={{
          mt: 20,
          display: 'flex',

          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              border={'1px solid rgb(200, 200, 200)'}
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${SilvioPresentaton1})`,
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {'Registrazione'}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {'Registrazione Pazienti Semplificata'}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {
                  "Gestione rapida e sicura dei dati dei pazienti, con aggiornamenti in tempo reale per un'amministrazione più efficiente."
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            order={{
              xs: 1,
              sm: 1,
              md: 0,
              lg: 0,
              xl: 0,
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {'Appuntamenti'}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {'Calendario Appuntamenti Interattivo'}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {
                  'Visualizzazione dinamica e personalizzata degli appuntamenti, con notifiche integrate per promemoria automatici e riduzione delle mancate presenze.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              component="img"
              border={'1px solid rgb(200, 200, 200)'}
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${SilvioPresentation2})`,
                backgroundPosition: 'left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            {/* <Paper elevation={20}> */}
            <Box
              border={'1px solid rgb(200, 200, 200)'}
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                //  bgcolor: 'gray',
                backgroundImage: `url(${SilvioPresentation3})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
            {/* </Paper> */}
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {'Intrattenimento'}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {'App Mobile con Giochi per Pazienti'}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {
                  "Giochi interattivi per intrattenere i pazienti durante l’attesa, favorendo un ambiente più piacevole in sala d'attesa."
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          mb: 10,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            order={{
              xs: 1,
              sm: 1,
              md: 0,
              lg: 0,
              xl: 0,
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {'Assistenza'}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {'Assistenza Clienti AI Intelligente'}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {
                  'Supporto clienti basato su AI per rispondere rapidamente alle domande frequenti e migliorare l’esperienza del paziente.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${SilvioPresentation4})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

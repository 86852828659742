import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTranslation } from 'react-i18next';
import PiattoQR from '../../assets/silvio_long_logo_white.png';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Flag from 'react-world-flags';

export default function AppFooter() {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);
  const handleChange = (event) => {
    let newValue = event.target.value as string;
    setLanguage(newValue);
    i18n.changeLanguage(newValue);
  };

  useEffect(() => {
    setLanguage(i18n.language);
  }, []);
  return (
    <Box
      component={'footer'}
      sx={{
        backgroundColor: `#222222`,
      }}
      paddingLeft={{ xs: 5, sm: 5, md: 5, lg: 20 }}
      paddingRight={{ xs: 5, sm: 5, md: 5, lg: 20 }}
      paddingTop={'80px'}
      paddingBottom={20}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          display={'flex'}
          justifyContent={'start'}
        >
          <Link
            href="/"
            sx={{
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Box component={'img'} src={PiattoQR} height={'40px'} />
          </Link>

          {/*

          <Box
            sx={{
              marginRight: '10px',
              minWidth: '150px',
            }}
            display={{
              xs: 'block',
              sm: 'none',
            }}
            marginLeft={2}
          >
            <FormControl>
              <Select
                id="demo-simple-select-2"
                value={language}
                onChange={handleChange}
                disableUnderline={true}
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important',
                  },
                }}
              >
                <MenuItem value={'en'}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                        borderRadius: '10px',
                      }}
                      code={'usa'}
                    />
                    <div>{t('english')}</div>
                  </div>
                </MenuItem>
                <MenuItem value={'es'}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                        borderRadius: '10px',
                      }}
                      code={'es'}
                    />
                    <div>{t('spanish')}</div>
                  </div>
                </MenuItem>
                <MenuItem
                  value={'it'}
                  sx={{
                    display: 'flex',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                        borderRadius: '10px',
                      }}
                      code={'ita'}
                    />
                    <div>{t('italian')}</div>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          */}
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Box
            display={'flex'}
            width={'100%'}
            justifyContent={{
              xs: 'start',
              sm: 'start',
              md: 'end',
            }}
            marginTop={{
              xs: 2,
              sm: 2,
            }}
          >
            {/*
            <Box
              sx={{
                marginRight: '10px',
                minWidth: '150px',
              }}
              display={{
                xs: 'none',
                sm: 'block',
              }}
            >
              <FormControl>
                <Select
                  id="demo-simple-select"
                  value={language}
                  onChange={handleChange}
                  disableUnderline={true}
                  sx={{
                    color: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                      fill: 'white !important',
                    },
                  }}
                >
                  <MenuItem value={'en'}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Flag
                        style={{
                          width: '30px',
                          height: '30px',
                          marginRight: '10px',
                          borderRadius: '10px',
                        }}
                        code={'usa'}
                      />
                      <div>{t('english')}</div>
                    </div>
                  </MenuItem>
                  <MenuItem value={'es'}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Flag
                        style={{
                          width: '30px',
                          height: '30px',
                          marginRight: '10px',
                          borderRadius: '10px',
                        }}
                        code={'es'}
                      />
                      <div>{t('spanish')}</div>
                    </div>
                  </MenuItem>
                  <MenuItem
                    value={'it'}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Flag
                        style={{
                          width: '30px',
                          height: '30px',
                          marginRight: '10px',
                          borderRadius: '10px',
                        }}
                        code={'ita'}
                      />
                      <div>{t('italian')}</div>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

                      */}

            <Link
              color={'rgb(245, 245, 245)'}
              fontSize={{
                xs: 12,
                sm: 14,
              }}
              ml={{ xs: 0, sm: 0, md: 2 }}
              sx={{
                marginRight: '10px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              href="/privacyPolicy"
            >
              {t('privacyPolicy')}
            </Link>

            <Link
              color={'rgb(245, 245, 245)'}
              fontSize={{
                xs: 12,
                sm: 14,
              }}
              sx={{
                marginRight: '10px',
                marginLeft: '20px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              href="/terms"
            >
              {t('termsAndConditions')}
            </Link>
          </Box>
        </Grid>
      </Grid>

      <Divider
        sx={{
          marginTop: '32px',
          marginBottom: '32px',
          bgcolor: 'gray',
        }}
      />

      <Box display={'flex'} justifyContent={'space-between'}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              fontSize={12}
              color={'white'}
              minWidth={'250px'}
              textAlign={'start'}
            >
              © 2024 Silvio. {t('allRightsReserved')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              display={'flex'}
              justifyContent={{
                xs: 'start',
                sm: 'end',
              }}
              marginTop={{
                xs: 1,
                sm: 0,
              }}
            >
              {/*<Button
                sx={{
                  minWidth: '0px',
                  color: 'white',
                }}
              >
                <FacebookIcon />
              </Button> */}

              <Button
                sx={{
                  minWidth: '0px',
                  color: 'white',
                }}
                onClick={() => {
                  window.open('https://x.com/PiattoQR', '_self');
                }}
              >
                <XIcon />
              </Button>

              {/*<Button
                sx={{
                  minWidth: '0px',
                  color: 'white',
                }}
                onClick={() => {
                  window.open('https://www.instagram.com/piattoqr/');
                }}
              >
                <InstagramIcon />
              </Button>*/}

              {/*<LinkedInIcon sx={{ marginRight: "10px", marginLeft: "20px" }} />*/}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

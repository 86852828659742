import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import { Check, Circle, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

// Define styles using makeStyles hook

interface PricingCardProps {
  title: string;
  price: string;
  label: string;
  features: Array<string>;
  description: string;
  buttonText: string;
  onClick: Function;
  headerColor?: string;
  isFree: boolean;
  href?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  features,
  description,
  buttonText,
  label,
  headerColor,
  isFree,
  href,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      elevation={0}
      sx={{
        border: '1px solid rgb(220, 220, 220)',
        borderRadius: '7px',
        height: '100%',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          height: '100%',
          minHeight: '300px',
        }}
      >
        <Box bgcolor={headerColor ? headerColor : 'lightgrey'}>
          <CardContent>
            <Stack spacing={1} alignItems={'center'}>
              {label !== '' ? (
                <Chip
                  label={label}
                  sx={{
                    backgroundColor: 'white',
                    opacity: 0.7,
                  }}
                />
              ) : null}

              <Typography fontWeight={'bold'} fontSize={25}>
                {title}
              </Typography>
            </Stack>
          </CardContent>
          <Divider />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <CardContent>
            <List sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
              {features.map((e, i) => (
                <ListItem key={i}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <CheckCircleRoundedIcon
                      fontSize="medium"
                      color="secondary"
                      sx={{
                        fontSize: '1rem',
                        color: 'secondary',
                      }}
                    />

                    <Typography color={'InfoText'} fontSize={18}>
                      {e}
                    </Typography>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Box>
        <Box>
          <Divider />
          <CardContent
            sx={{
              height: '80px',
            }}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography sx={{ mr: 1, ml: 1 }}>
                <Stack direction={'row'} spacing={1} alignItems={'baseline'}>
                  <Typography
                    color="secondary"
                    fontSize={'22px'}
                    fontWeight={'bold'}
                  >
                    {price}
                  </Typography>

                  {!isFree ? (
                    <Typography color={'secondary'} fontSize={'1.2rem'}>
                      €
                    </Typography>
                  ) : null}
                </Stack>
                {!isFree ? (
                  <Typography fontSize="12px">{'al mese'}</Typography>
                ) : null}
              </Typography>
              <Button
                disableRipple
                href={href}
                variant="contained"
                color="secondary"
                endIcon={<KeyboardArrowRight />}
                disableElevation={true}
                sx={{
                  color: 'white',
                  height: '50px',
                }}
              >
                {buttonText}
              </Button>
            </Stack>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
};

export default PricingCard;

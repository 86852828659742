import { Box, Container } from '@mui/material';
import React from 'react';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import PolicyIt from './i18n/locales/it/PolicyIt';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { i18n } = useTranslation();

  function termByLanguage() {
    /*
    if (i18n.language == 'en') {
      return <PolicyEn />;
    } else if (i18n.language == 'es') {
      return <PolicyEs />;
    } else if (i18n.language == 'it') {
      return <PolicyIt />;
    }
      */
    return <PolicyIt />;
  }
  return (
    <React.Fragment>
      <AppAppBar hideNavigation={true} />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>{termByLanguage()}</Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default PrivacyPolicy;

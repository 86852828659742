import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function ProductCTA() {
  const { t } = useTranslation();
  return (
    <Container
      id="FAQ"
      maxWidth={false}
      component="section"
      sx={{
        mt: 10,
        bgcolor: 'white',
        pt: 10,
        pl: {
          xs: 5,
          sm: 5,
          md: 5,
          lg: 20,
          xl: 20,
        },
        pr: {
          xs: 5,
          sm: 5,
          md: 5,
          lg: 20,
          xl: 20,
        },
      }}
      disableGutters
    >
      <Typography variant="h4" align="center" component="h2" marginBottom={7}>
        {t('commonly_asked_questions')}
      </Typography>

      <Accordion
        defaultExpanded
        sx={{
          mt: 10,
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            borderRadius: 1,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {'Come posso registrare un nuovo paziente?'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>
            {
              'La registrazione di un nuovo paziente avviene tramite il modulo dedicato, dove è possibile inserire i dati personali e le informazioni cliniche in modo rapido e sicuro.'
            }
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {'Come funziona il calendario degli appuntamenti?'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>
            {
              'Il calendario permette di visualizzare e gestire gli appuntamenti, inviare promemoria ai pazienti e ottimizzare le visite per ridurre i tempi di attesa.'
            }
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {'Come accedo ai report di analisi dei dati?'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>
            {
              "I report sono accessibili dalla sezione 'Analisi', dove è possibile visualizzare dati statistici e metriche di performance per un monitoraggio efficace."
            }
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {"L'assistenza AI è disponibile 24/7?"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>
            {
              "Sì, l'assistenza clienti AI è sempre attiva e risponde alle domande frequenti in modo istantaneo, fornendo supporto continuo ai pazienti."
            }
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default ProductCTA;

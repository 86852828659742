import './App.css';
import Home from './Home';
import { extendedTheme } from './providers/extendedTheme';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './i18n/config';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';

function App() {
  return (
    <CssVarsProvider theme={extendedTheme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </BrowserRouter>
      </div>
    </CssVarsProvider>
  );
}

export default App;

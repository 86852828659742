import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import withRoot from '../withRoot';
import PricingCard from '../components/PricingCard';
import { useTranslation } from 'react-i18next';

function PricingPage() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Container
        id="pricing"
        component="section"
        sx={{
          px: {
            xs: 5,
            sm: 10,
            md: 20,
            lg: 20,
            xl: 20,
          },
          py: '60px',
        }}
      >
        <Typography variant="h4" align="center" component="h2">
          {t('pricing_uppercase')}
        </Typography>

        <Box>
          <Grid container spacing={4} mt={3}>
            <Grid item xs={12} sm={6} md={6}>
              <PricingCard
                title={'Basic'}
                features={['300 clienti', '1 gioco per pacienti']}
                description=""
                price={'9.99'}
                label={''}
                buttonText={'INIZIARE'}
                headerColor="white"
                onClick={() => {}}
                isFree={false}
                href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=free`}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <PricingCard
                title="Pro"
                features={[
                  '1000 clienti',
                  '5 giochi per pacienti',
                  'Notifiche Whatsapp',
                  "Notifiche nell'app",
                  'Supporto avanzato',
                ]}
                description=""
                price="19.99"
                label={'PIU COMUNE'}
                headerColor="white"
                buttonText={'INIZIARE'}
                onClick={() => {}}
                isFree={false}
                href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=basic`}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default withRoot(PricingPage);

import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { Box, Container } from '@mui/material';
import { extendedTheme } from '../../providers/extendedTheme';
import { useTranslation } from 'react-i18next';

import PresentationEnglish from '../../assets/presentation_english.png';
import PresentationSpanish from '../../assets/presentation_spanish.png';
import PresentationItalian from '../../assets/presentation_italian.png';

import SilvioPresentation from '../../assets/silvio_presentation.png';

import OnlyPhone from '../../assets/only_phone.png';

import './product_hero/ProductHeroStyle.css';

export default function ProductHero() {
  const { t, i18n } = useTranslation();

  function localizedPresentation() {
    if (i18n.language == 'en') {
      return SilvioPresentation;
    } else if (i18n.language == 'es') {
      return SilvioPresentation;
    } else if (i18n.language == 'it') {
      return SilvioPresentation;
    }

    return SilvioPresentation;
  }

  const [words, setWords] = React.useState([
    'Dentistico',
    'Medico',
    'Dentistico',
    'Medico',
  ]);

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundColor: 'white',
      }}
    >
      <Container
        id="home"
        sx={{
          px: {
            xs: 5,
            sm: 10,
            md: 30,
            lg: 30,
            xl: 30,
          },
        }}
      >
        <Typography
          fontSize={{
            xs: 45,
            sm: 45,
            md: 45,
            lg: 55,
            xl: 55,
          }}
          fontWeight={700}
        >
          {'Software intelligente per il tuo studio'}
        </Typography>
        <div className="list-container">
          <div className="scroller">
            <span>
              {words[0]}
              <br />
              <br />
              {words[1]}
              <br />
              <br />
              {words[2]}
              <br />
              <br />
              {words[3]}
            </span>
          </div>
        </div>

        <Typography
          color="black"
          align="center"
          variant="h5"
          sx={{
            mb: 10,

            mt: { xs: 2, sm: 2 },
          }}
        >
          {
            'Un sistema semplice e dinamico per migliorare i rapporti con i clienti, offrendo una piattaforma interattiva e coinvolgente.'
          }
        </Typography>
      </Container>
      <div>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          href={`${process.env.REACT_APP_ADMIN_URL}/register`}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            minWidth: 200,
            color: 'white',
            mb: 4,
            borderRadius: '5px',
          }}
        >
          {'INIZA ORA'}
        </Button>

        <Button
          color="secondary"
          variant="outlined"
          size="large"
          component="a"
          href={`${process.env.REACT_APP_ADMIN_URL}/t119K3a`}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            minWidth: 200,
            color: (t) =>
              extendedTheme.colorSchemes.light.palette.secondary.light,
            mb: 4,
            borderRadius: '5px',
          }}
        >
          {'DEMO'}
        </Button>
      </div>

      <Box
        component="img"
        src={localizedPresentation()}
        sx={{
          mt: 5,
        }}
        width={'65%'}
        marginLeft={'auto'}
        marginRight={'auto'}
        display={{
          xs: 'none',
          sm: 'none',
          md: 'flex',
          lg: 'flex',
          xl: 'flex',
        }}
      />

      <Box
        component="img"
        src={OnlyPhone}
        width={'80%'}
        sx={{
          mt: 5,
        }}
        display={{
          md: 'none',
          lg: 'none',
          xl: 'none',
        }}
      />
    </ProductHeroLayout>
  );
}

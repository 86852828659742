import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { useTranslation } from 'react-i18next';

function ProductSmokingHero() {
  const { t } = useTranslation();
  return (
    <Container
      id="contact"
      component="section"
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        bgcolor: 'white',
        py: 9,
      }}
    >
      <Button
        sx={{
          border: '4px solid currentColor',
          borderRadius: 1,
          height: 'auto',
          py: 2,
          px: 5,
        }}
        disableRipple
        href="mailto:info@silviomed.com"
      >
        <Typography variant="h4" component="span">
          {'Contatto'}
        </Typography>
      </Button>
      <Typography variant="subtitle1" sx={{ mt: 3 }}>
        {"Per maggiori informazioni, invia un'email"}
      </Typography>

      <Typography
        fontWeight={600}
        color={'secondary'}
      >{` ${'info@silviomed.com'}`}</Typography>
    </Container>
  );
}

export default ProductSmokingHero;

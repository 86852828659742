export default function PolicyIt() {
  return (
    <div>
      <h2>Informativa sulla Privacy</h2>

      <p>
        Ultimo Aggiornamento: 24/03/2024 SilvioMed ("noi", "ci" o "nostro")
        rispetta la tua privacy e si impegna a proteggerla attraverso il
        rispetto di questa politica. Questa Informativa sulla Privacy descrive i
        tipi di informazioni che possiamo raccogliere da te o che tu puoi
        fornire quando visiti il sito web di SilvioMed e le nostre pratiche per
        raccogliere, utilizzare, mantenere, proteggere e divulgare tali
        informazioni.
      </p>

      <h2>Informazioni che Raccogliamo e Come le Utilizziamo</h2>

      <p>
        Possiamo raccogliere diversi tipi di informazioni dagli utenti del
        nostro Servizio, tra cui informazioni:
      </p>

      <p>
        Attraverso cui potresti essere identificato personalmente, come nome,
        indirizzo postale, indirizzo e-mail, numero di telefono o qualsiasi
        altro identificatore tramite cui puoi essere contattato online o offline
        ("informazioni personali"). Che riguardano te ma non ti identificano
        individualmente, come i dettagli del tuo utilizzo, l'indirizzo IP, il
        tipo di browser e le informazioni raccolte tramite cookie, web beacon e
        altre tecnologie di tracciamento. Sulla tua connessione internet,
        l'equipaggiamento che utilizzi per accedere al nostro Servizio e i
        dettagli sull'utilizzo. Raccogliamo queste informazioni:
      </p>

      <p>
        Direttamente da te quando ce le fornisci. Automaticamente mentre navighi
        nel sito. Le informazioni raccolte automaticamente possono includere
        dettagli sull'utilizzo, indirizzi IP e informazioni raccolte tramite
        cookie, web beacon e altre tecnologie di tracciamento. Da terze parti,
        ad esempio i nostri partner commerciali.
      </p>

      <p>
        Utilizziamo le informazioni che raccogliamo su di te o che ci fornisci,
        comprese eventuali informazioni personali:
      </p>

      <p>
        Per presentare il nostro Servizio e i suoi contenuti a te. Per fornirti
        informazioni, prodotti o servizi che richiedi da noi. Per soddisfare
        qualsiasi altro scopo per cui la fornisci. Per adempiere ai nostri
        obblighi e far valere i nostri diritti derivanti da eventuali contratti
        stipulati tra te e noi, inclusi per la fatturazione e la riscossione.
        Per informarti riguardo a modifiche al nostro Servizio o a qualsiasi
        prodotto o servizio che offriamo o forniamo tramite esso. In qualsiasi
        altro modo che possiamo descrivere quando fornisci le informazioni. Per
        qualsiasi altro scopo con il tuo consenso.
      </p>

      <p>
        Possiamo anche utilizzare le tue informazioni per contattarti riguardo a
        prodotti e servizi nostri e di terze parti che potrebbero interessarti.
        Se non desideri che utilizziamo le tue informazioni in questo modo, ti
        preghiamo di spuntare la casella pertinente sul modulo in cui
        raccogliamo i tuoi dati (il modulo di registrazione o la pagina di
        checkout). Per ulteriori informazioni, consulta "Scelte su Come
        Utilizziamo e Divulghiamo le Tue Informazioni".
      </p>

      <h2>Divulgazione delle Tue Informazioni</h2>

      <p>
        Possiamo divulgare informazioni aggregate sui nostri utenti e
        informazioni che non identificano nessun individuo, senza restrizioni.
      </p>

      <p>
        Possiamo divulgare informazioni personali che raccogliamo o che ci
        fornisci come descritto in questa informativa sulla privacy:
      </p>

      <p>
        Ai nostri sussidiari e affiliati. A contraenti, fornitori di servizi e
        altre terze parti che utilizziamo per supportare la nostra attività. A
        un acquirente o altro successore in caso di fusione, cessione,
        ristrutturazione, riorganizzazione, scioglimento o altra vendita o
        trasferimento di alcuni o tutti i beni di SilvioMed, sia come attività
        in corso che come parte di bancarotta, liquidazione o procedura simile,
        in cui le informazioni personali detenute da SilvioMed riguardo agli
        utenti del nostro Servizio fanno parte dei beni trasferiti. Per
        soddisfare lo scopo per cui le fornisci. Per qualsiasi altro scopo
        divulgato da noi quando fornisci le informazioni. Con il tuo consenso.
        Per rispettare qualsiasi ordine del tribunale, legge o processo legale,
        inclusa la risposta a qualsiasi richiesta governativa o regolatoria. Per
        far rispettare o applicare i nostri Termini di Utilizzo e altri accordi,
        inclusi a fini di fatturazione e riscossione. Se riteniamo che la
        divulgazione sia necessaria o appropriata per proteggere i diritti, la
        proprietà o la sicurezza di SilvioMed, dei nostri clienti o di altri.
        Questo include lo scambio di informazioni con altre aziende e
        organizzazioni ai fini della protezione contro le frodi e la riduzione
        del rischio di credito.
      </p>

      <h2>Scelte su Come Utilizziamo e Divulghiamo le Tue Informazioni</h2>

      <p>
        Ci impegniamo a offrirti scelte riguardo alle informazioni personali che
        ci fornisci. Abbiamo creato meccanismi per darti il controllo sulle tue
        informazioni:
      </p>

      <p>
        Tecnologie di Tracciamento e Pubblicità. Puoi impostare il tuo browser
        per rifiutare tutti o alcuni cookie del browser o per avvisarti quando i
        cookie vengono inviati. Se disabiliti o rifiuti i cookie, nota che
        alcune parti di questo sito potrebbero essere inaccessibili o non
        funzionare correttamente.
      </p>

      <p>
        Offerte Promozionali della Società. Se non desideri che il tuo indirizzo
        email/informazioni di contatto vengano utilizzati dalla Società per
        promuovere i nostri o i prodotti o servizi di terze parti, puoi
        rinunciare spuntando la casella pertinente sul modulo in cui raccogliamo
        i tuoi dati o inviandoci un'email in cui richiedi di essere escluso da
        future distribuzioni email. Questo opt-out non si applica alle
        informazioni fornite alla Società come risultato di un acquisto di
        prodotto, registrazione di garanzia, esperienza di servizio prodotto o
        altre transazioni.
      </p>

      <h2>Accesso e Correzione delle Tue Informazioni</h2>

      <p>
        Puoi rivedere e modificare le tue informazioni personali effettuando il
        login al sito web e visitando la pagina del profilo del tuo account.
      </p>

      <p>
        Puoi anche inviarci un'email a [inserisci indirizzo email] per
        richiedere accesso, correggere o eliminare qualsiasi informazione
        personale che ci hai fornito. Non possiamo eliminare le tue informazioni
        personali se non eliminando anche il tuo account utente. Potremmo non
        accogliere una richiesta di modifica delle informazioni se riteniamo che
        la modifica violerebbe qualsiasi legge o requisito legale o renderebbe
        le informazioni errate.
      </p>

      <p>
        Se elimini i tuoi Contributi Utente dal Servizio, copie dei tuoi
        Contributi Utente potrebbero rimanere visibili in pagine cache e
        archiviate, o potrebbero essere state copiate o memorizzate da altri
        utenti del Servizio.
      </p>

      <h2>Sicurezza dei Dati</h2>

      <p>
        Abbiamo implementato misure progettate per proteggere le tue
        informazioni personali da perdita accidentale e da accessi, usi,
        alterazioni e divulgazioni non autorizzati. Tutte le informazioni che ci
        fornisci sono memorizzate sui nostri server sicuri dietro firewall.
      </p>

      <p>
        La sicurezza delle tue informazioni dipende anche da te. Dove ti abbiamo
        fornito (o dove hai scelto) una password per l'accesso a determinate
        parti del nostro Servizio, sei responsabile di mantenere questa password
        confidenziale. Ti chiediamo di non condividere la tua password con
        nessuno. Ti invitiamo a prestare attenzione nel fornire informazioni in
        aree pubbliche del Servizio come le bacheche dei messaggi. Le
        informazioni che condividi nelle aree pubbliche possono essere
        visualizzate da qualsiasi utente del Servizio.
      </p>

      <h2>Modifiche alla Nostra Informativa sulla Privacy</h2>

      <p>
        È nostra politica pubblicare eventuali modifiche che apportiamo alla
        nostra informativa sulla privacy su questa pagina. Se apportiamo
        modifiche sostanziali al modo in cui trattiamo le informazioni personali
        dei nostri utenti, ti avviseremo tramite un avviso sulla home page del
        sito web. La data in cui l'informativa sulla privacy è stata aggiornata
        l'ultima volta è indicata nella parte superiore della pagina. Sei
        responsabile di assicurarti che abbiamo un indirizzo email attivo e
        consegnabile aggiornato per te e per visitare periodicamente il nostro
        sito web e questa informativa sulla privacy per verificare eventuali
        modifiche.
      </p>

      <h2>Informazioni di Contatto</h2>

      <p>
        Per domande o commenti su questa informativa sulla privacy e sulle
        nostre pratiche di privacy, contattaci a:
        alejandro.hernandez@silviomed.com.
      </p>
    </div>
  );
}
